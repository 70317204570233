import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import "../style/CSS/Navbar.css";
import logo from "../assets/logo-ics.png";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState({
    products: false,
    services: false,
    language: false,
  });
  const { t, i18n } = useTranslation(); // Destructure i18n from useTranslation hook

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleDropdown = (menu) => {
    if (window.innerWidth <= 1150) {
      // Only allow toggle on mobile
      setShowDropdown((prev) => ({ ...prev, [menu]: !prev[menu] }));
    }
  };

  const closeDropdownAndMenu = () => {
    setShowDropdown({ products: false, services: false });
    if (window.innerWidth <= 1150) {
      // Close menu on mobile
      setShowMenu(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the app language
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1150) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <nav className="nav container">
        <NavLink to="/" className="nav__logo">
          <img src={logo} alt="Logo" />
        </NavLink>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink
                to="/"
                className="nav__link"
                onClick={closeDropdownAndMenu}
              >
                {t("nav-home")}
              </NavLink>
            </li>
            <li className="nav__item">
              <div
                className="nav__link dropdown"
                onClick={() => toggleDropdown("products")}
              >
                {t("nav-products")}
                <ul
                  className={`dropdown-menu ${
                    showDropdown.products ? "show-dropdown" : ""
                  }`}
                >
                  <li>
                    <NavLink
                      to="/soon"
                      className="nav__link"
                      onClick={closeDropdownAndMenu}
                    >
                      soon!
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink
                      to="/soon"
                      className="nav__link"
                      onClick={closeDropdownAndMenu}
                    >
                      Sustainable Resources Products - soon!
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            </li>
            <li className="nav__item">
              <div
                className="nav__link dropdown"
                onClick={() => toggleDropdown("services")}
              >
                {t("nav-services")}
                <ul
                  className={`dropdown-menu ${
                    showDropdown.services ? "show-dropdown" : ""
                  }`}
                >
                  <li>
                    <NavLink
                      to="/soon"
                      className="nav__link"
                      onClick={closeDropdownAndMenu}
                    >
                      Real Estate - soon!
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/soon"
                      className="nav__link"
                      onClick={closeDropdownAndMenu}
                    >
                      Tourism - soon!
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/soon"
                      className="nav__link"
                      onClick={closeDropdownAndMenu}
                    >
                      Business Setup - soon!
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav__item">
              <NavLink
                to="/about-us"
                className="nav__link"
                onClick={closeDropdownAndMenu}
              >
                {t("nav-about-us")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/contact"
                className="nav__link"
                onClick={closeDropdownAndMenu}
              >
                {t("nav-contact")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/location"
                className="nav__link"
                onClick={closeDropdownAndMenu}
              >
                {t("nav-location")}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/soon" className="nav__link nav__cta">
                {t("nav-get-started")}
              </NavLink>
            </li>

            {/* Language Switcher Dropdown */}
            <li className="nav__item">
              <div
                className="nav__link dropdown"
                onClick={() => toggleDropdown("language")}
              >
                {t("nav-language")}
                <ul
                  className={`dropdown-menu ${
                    showDropdown.language ? "show-dropdown" : ""
                  }`}
                >
                  <li
                    onClick={() => changeLanguage("en")}
                    className="nav__link"
                  >
                    English
                  </li>
                  <li
                    onClick={() => changeLanguage("es")}
                    className="nav__link"
                  >
                    Española
                  </li>
                  {/* Add more languages here */}
                </ul>
              </div>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
