import React from "react";
import { useNavigate } from "react-router-dom";
import "../style/CSS/Soon.css";

const Soon = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="soon-container">
      <h1 className="soon-title">Currently Under Construction</h1>
      <p className="soon-subtitle">Come back soon!</p>
      <button className="soon-button" onClick={handleGoHome}>
        Go Back to Home
      </button>
    </div>
  );
};

export default Soon;
