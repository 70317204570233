import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import Products from "./components/Products";
import Services from "./components/Services";
import Location from "./components/Location";
import NavBar from "./components/Navbar";
import Footer from "./components/Footer";
import Soon from "./components/Soon";
import "./App.css";

function App() {
  return (
    <div className="app-container">
      <NavBar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/location" element={<Location />} />
          <Route path="/soon" element={<Soon />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
