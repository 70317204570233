import React from 'react';
// import '../style/CSS/Products.css';

const Products = () => {
  return (
    <main className="products">
      <section className="products-intro">
        <h1>Our Products</h1>
        <p>At Global Consulting and Services, we are dedicated to offering innovative and <br/> sustainable products that are made from natural materials and sustainable resources. <br /> Our products are designed to minimize environmental impact and contribute to a greener future.</p>
        <p>Explore our range of green products that are crafted with care <br/> to ensure both quality and sustainability. Here are some examples of the types of products we offer:</p>
      </section>
      <section className="products-list">
        <div className="product-item">
          <h2>Eco-Friendly Packaging</h2>
          <p>Our packaging solutions are made from recycled materials and designed to be biodegradable, reducing waste and environmental impact.</p>
        </div>
        <div className="product-item">
          <h2>Biodegradable Cleaning Products</h2>
          <p>We offer a range of cleaning products that are both effective and environmentally friendly, using natural ingredients that break down easily.</p>
        </div>
        <div className="product-item">
          <h2>Sustainable Building Materials</h2>
          <p>Our building materials are sourced from sustainable resources and are designed to have a minimal impact on the environment, promoting eco-friendly construction practices.</p>
        </div>
        <div className="product-item">
          <h2>Green Energy Solutions</h2>
          <p>We provide energy solutions that utilize renewable sources such as solar and wind, helping you reduce your carbon footprint and reliance on fossil fuels.</p>
        </div>
        <div className="product-item">
          <h2>Organic Textile Products</h2>
          <p>Our textile products are made from organic fibers and dyes, ensuring they are free from harmful chemicals and contribute to a healthier planet.</p>
        </div>
      </section>
    </main>
  );
};

export default Products;
