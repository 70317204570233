import React from 'react';
import '../style/CSS/Contact.css';
import phone from "../assets/phone.png"
import globe from "../assets/globe.png"
import email from "../assets/email.png"
import location from "../assets/location.png"
import name from "../assets/user.png"
import message from "../assets/message.png"

const Contact = () => {
  return (
    <main className="contact">
      <section className="contact-info">
        <h1>Contact Us</h1>
        <p> <img src={location} className='contact-icon' alt='icon'/> &nbsp; Lisbon, Portugal</p>
        <p> <img src={phone} className='contact-icon' alt='icon'/> &nbsp; <strong>Mobile:</strong> +917 50 2959 231</p>
        <p> <img src={globe} className='contact-icon' alt='icon'/> &nbsp; <strong>Website:</strong> <a href="http://www.intercontinental-me.com" target="_blank" rel="noopener noreferrer">www.intercontinental-me.com</a></p>
        <p> <img src={email} className='contact-icon' alt='icon'/> &nbsp; <strong>Email:</strong> <a href="mailto:Marketingroutes@gmail.com">Marketingroutes@gmail.com</a></p>
      </section>

      <section className="contact-form">
        <h2>Get in Touch</h2>
        <p>Feel free to reach out to us with any inquiries or feedback. We are here to assist you and provide the best possible service.</p>
        <form action="https://formspree.io/f/mnnalqzz" method="POST">
          <div className="form-group">
            <label htmlFor="name"> <img src={name} className='contact-icon' alt='icon'/> &nbsp; Name</label>
            <input type="text" id="name" name="name" required placeholder='Your Name' />
          </div>
          <div className="form-group">
            <label htmlFor="email"> <img src={email} className='contact-icon' alt='icon'/> &nbsp; Email</label>
            <input type="email" id="email" name="email" required placeholder='Email Address' />
          </div>
          <div className="form-group">
            <label htmlFor="message"> <img src={message} className='contact-icon' alt='icon'/> &nbsp; Message</label>
            <textarea id="message" name="message" rows="5" required placeholder='Your Message'></textarea>
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </section>
    </main>
  );
};

export default Contact;
