import React from "react";
// import "../style/CSS/Services.css";

const Services = () => {
  return (
    <main className="services">
      <section className="services-intro">
        <h1>Our Services</h1>
        <p>
          At Global Consulting and Services, we offer a range of specialized <br/>
          services designed to meet your needs and contribute to a sustainable
          future. <br/> Our services are tailored to provide innovative solutions
          across various sectors. Here's what we offer:
        </p>
      </section>
      <section className="services-list">
        <div className="service-item">
          <h2>Agricultural Solutions</h2>
          <p>Coming Soon</p>
        </div>
        <div className="service-item">
          <h2>Sustainability</h2>
          <p>Coming Soon</p>
        </div>
        <div className="service-item">
          <h2>Water Plant Solutions</h2>
          <p>Coming Soon</p>
        </div>
      </section>
      <br />
    </main>
  );
};

export default Services;
