import React from "react";
import "../style/CSS/About.css";
import about from "../assets/about.png"
// import check from "../assets/check.png"
// import values from "../assets/values.png"
import valuesImg from "../assets/values-img.jpg"
import mission from "../assets/our-mission.png"

const About = () => {
  return (
    <main className="about">
      <section className="about-section">
        <h1>
          About Us <img src={about} className="about-icon" alt="icon" />
        </h1>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;ICS is a consortium of investors and experts
          from various fields.
          <br /> We specialize in providing real estate services across multiple
          countries.
        </p>
      </section>

      <div className="about-flex">
        <section className="mission-section">
          {/* <h2>
            Our Mission{" "}
            <img src={check} className="check-icon" alt="icon" />
          </h2> */}
          <img src={mission} className="mission-badge" alt="icon" />
          <p>
            At ICS, our mission is to provide
            <br /> outstanding real estate services through
            <br /> expert solutions and innovative strategies.
          </p>
        </section>

        <section className="values-section">
          {/* <h2>
            Our Values{" "}
            <img src={values} className="about-icon" alt="icon" />
          </h2> */}
          <img src={valuesImg} className="values-badge" alt="icon" />
          <ul>
            <li>Honesty</li>
            <li>Transparency</li>
            <li>Timeliness</li>
            <li>Right Projects</li>
          </ul>
        </section>
      </div>
      <br></br>
      <br />
    </main>
  );
};

export default About;
