import React from "react";
import "../style/CSS/Location.css";
import location from "../assets/location.png";

const Location = () => {
  return (
    <main className="location">
      <div className="location-flex">
        <section className="location-info">
          <h1>
            {" "}
            <img src={location} className="location-icon" alt="icon" /> Our
            Location
          </h1>
          <p>We are located in the vibrant city of Lisbon, Portugal.</p>
          <p>
            Our office is situated in a prime and easily accessible area, making
            it convenient for all your business needs.
          </p>
          <p>
            Feel free to visit us for any inquiries or consultations. We look
            forward to welcoming you to our Lisbon office!
          </p>
        </section>
        <section className="location-map">
          <h2>Find Us Here</h2>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.5216804249454!2d-9.1426854!3d38.7168938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19347923359dff%3A0x6e2f39dfb202e34e!2sLisbon%2C%20Portugal!5e0!3m2!1sen!2s!4v1622471167658!5m2!1sen!2s"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              title="ICS Location"
            ></iframe>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Location;
