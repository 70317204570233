import React from 'react';
import '../style/CSS/Home.css';
import slide1 from '../assets/slide1-ics.jpg'
import slide2 from '../assets/slide2-ics.png'
import slide3 from '../assets/slide3-ics.png'
import slide4 from '../assets/slide4-ics.png'
import slide5 from '../assets/slide5-ics.png'
import slide6 from '../assets/slide6-ics.png'
import slide7 from '../assets/slide7-ics.png'
import soon from '../assets/soon.png'
import { useTranslation } from "react-i18next";
import Form from "./Form"
import video from "../assets/home-video.mp4"

const Home = () => {
  const { t } = useTranslation();
  return (
    <main className="home">
      <section className="slider">
        <div className="slider__container">
          <figure>
            <img src={slide1} alt="slide1" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide2} alt="slide2" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide3} alt="slide3" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide4} alt="slide4" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide5} alt="slide5" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide6} alt="slide6" className="slide-desktop" />
          </figure>
          <figure>
            <img src={slide7} alt="slide7" className="slide-desktop" />
          </figure>
        </div>
        {/* <div className="slider__content">
          <h1>{t("welcome")}</h1>
          <p>{t("welcome-bottom")}</p>
        </div> */}
      </section>
      {/* <section className="hero">
        <div className="hero-inner">
          <h1>Welcome to Global Consulting and Services</h1> <br />
          <p>Your partner in sustainable solutions.</p>
        </div>
      </section> */}
      <section className='welcome-section'>
        <h1>{t("welcome")}</h1>
        <p>{t("welcome-bottom")}</p>
      </section>
      <section>
        <h1 className="video-title">Get Started Today!</h1>
      </section>

      <section className="form-section">
        <video src={video} autoPlay loop muted />
        <Form />
      </section>
      <section className="products">
        <h2>
          Products
          <br />{" "}
          <span className="soon-span">
            {" "}
            <img src={soon} alt="soon" width={15} /> Coming soon!
          </span>
        </h2>
        <div className="product-list">
          <div className="product-item">
            <p>
              We are working on innovative products that align with our
              commitment to sustainability and excellence. Stay tuned!
            </p>
          </div>
        </div>
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <div className="service-list">
          <div className="service-item">
            <h3>Real Estate</h3>
            <p>
              Comprehensive real estate services to help you find the perfect
              property for your needs.
            </p>
          </div>
          <div className="service-item">
            <h3>Tourism</h3>
            <p>
              Exceptional tourism services that offer unique experiences
              tailored to your preferences.
            </p>
          </div>
          <div className="service-item">
            <h3>Business Setup Services</h3>
            <p>
              Expert guidance and support for establishing your business
              efficiently and effectively.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
