import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const Form = () => {
  const [state, handleSubmit] = useForm("mnnalqzz");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    country: null,
    propertyType: null,
    priceRange: null,
    buyOrRent: null,
    residencyCitizenship: null,
    project: null,
    investment: null,
  });
  const [errors, setErrors] = useState({});
  const countries = countryList().getData();

  if (state.succeeded) {
    return <h1 className='home-form' style={{ color: 'tomato'}}>Thanks for submitting!</h1>;
  }

  const propertyTypes = [
    { value: 'house', label: 'House' },
    { value: 'apartment', label: 'Apartment' },
    { value: 'commercial', label: 'Commercial' },
  ];

  const priceRange = [
    { value: 'up to 500 thousand', label: 'Up to 500 Thousands' },
    { value: '500k-1 million', label: '500 Thousand to 1 Million' },
    { value: '1 million to 1.5 millions', label: '1 Million to 1.5 Millions' },
    { value: '1.5 millions to 2 millions', label: '1.5 Millions to 2 Millions' },
    { value: 'more than 2 millions', label: 'More Than 2 Millions' },
  ];

  const buyOrRentOptions = [
    { value: 'buy', label: 'Buy' },
    { value: 'rent', label: 'Rent' },
    { value: 'both', label: 'Both' },
  ];

  const residencyCitizenshipOptions = [
    { value: 'residency', label: 'Residency' },
    { value: 'citizenship', label: 'Citizenship' },
    { value: 'both', label: 'Both' },
  ];

  const projectTypes = [
    { value: 'real_estate', label: 'Real Estate' },
    { value: 'construction', label: 'Construction' },
  ];

  const investmentTypes = [
    { value: 'stocks', label: 'Stocks' },
    { value: 'bonds', label: 'Bonds' },
  ];

  const handleValidation = () => {
    let formErrors = {};

    if (!formData.name) formErrors.name = "Name is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.country) formErrors.country = "Country is required";
    if (!formData.propertyType) formErrors.propertyType = "Property Type is required";
    if (!formData.priceRange) formErrors.priceRange = "Price Range is required";
    if (!formData.buyOrRent) formErrors.buyOrRent = "You must select Buy or Rent";
    if (!formData.residencyCitizenship) formErrors.residencyCitizenship = "Residency or Citizenship is required";
    if (!formData.project) formErrors.project = "Project Type is required";
    if (!formData.investment) formErrors.investment = "Investment Type is required";

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      // Prepare form data
      const form = event.target;
      const formElements = form.elements;

      formElements.country.value = formData.country?.label || '';
      formElements.propertyType.value = formData.propertyType?.label || '';
      formElements.priceRange.value = formData.priceRange?.label || '';
      formElements.buyOrRent.value = formData.buyOrRent?.label || '';
      formElements.residencyCitizenship.value = formData.residencyCitizenship?.label || '';
      formElements.project.value = formData.project?.label || '';
      formElements.investment.value = formData.investment?.label || '';

      handleSubmit(event);
    }
  };

  const handleChange = (selectedOption, field) => {
    if (field === 'name' || field === 'email') {
      setFormData({
        ...formData,
        [field]: selectedOption.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [field]: selectedOption,
      });
    }
  };

  return (
    <form onSubmit={onSubmit} className='home-form'>
      <div className='combo-form'>
      <div>
        <label htmlFor="name">Name</label><br/>
        <input
          type="text"
          name="name"
          id="name"
          value={formData.name}
          onChange={(e) => handleChange(e, 'name')}
          className='input-box'
          placeholder='Your Name'
        />
        {errors.name && <p className="error">{errors.name}</p>}
      </div>

      <div>
        <label htmlFor="email">Email</label><br/>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={(e) => handleChange(e, 'email')}
          className='input-box'
          placeholder='Your Email Address'
        />
        {errors.email && <p className="error">{errors.email}</p>}
      </div>

      
        <div>
          <label htmlFor="country">Country</label>
          <Select
            options={countries}
            name="country"
            id="country"
            value={formData.country}
            onChange={(selectedOption) => handleChange(selectedOption, 'country')}
          />
          {errors.country && <p className="error">{errors.country}</p>}
        </div>

        <div>
          <label htmlFor="propertyType">Property Type</label>
          <Select
            options={propertyTypes}
            name="propertyType"
            id="propertyType"
            value={formData.propertyType}
            onChange={(selectedOption) => handleChange(selectedOption, 'propertyType')}
          />
          {errors.propertyType && <p className="error">{errors.propertyType}</p>}
        </div>

        <div>
          <label htmlFor="priceRange">Price Range</label>
          <Select
            options={priceRange}
            name="priceRange"
            id="priceRange"
            value={formData.priceRange}
            onChange={(selectedOption) => handleChange(selectedOption, 'priceRange')}
          />
          {errors.priceRange && <p className="error">{errors.priceRange}</p>}
        </div>
      </div>
      <div className='combo-form'>
        <div>
          <label htmlFor="buyOrRent">Buy or Rent</label>
          <Select
            options={buyOrRentOptions}
            name="buyOrRent"
            id="buyOrRent"
            value={formData.buyOrRent}
            onChange={(selectedOption) => handleChange(selectedOption, 'buyOrRent')}
          />
          {errors.buyOrRent && <p className="error">{errors.buyOrRent}</p>}
        </div>

      
        <div>
          <label htmlFor="residencyCitizenship">Residency or Citizenship</label>
          <Select
            options={residencyCitizenshipOptions}
            name="residencyCitizenship"
            id="residencyCitizenship"
            value={formData.residencyCitizenship}
            onChange={(selectedOption) => handleChange(selectedOption, 'residencyCitizenship')}
          />
          {errors.residencyCitizenship && <p className="error">{errors.residencyCitizenship}</p>}
        </div>

        <div>
          <label htmlFor="project">Project Type</label>
          <Select
            options={projectTypes}
            name="project"
            id="project"
            value={formData.project}
            onChange={(selectedOption) => handleChange(selectedOption, 'project')}
          />
          {errors.project && <p className="error">{errors.project}</p>}
        </div>

        <div>
          <label htmlFor="investment">Investment Type</label>
          <Select
            options={investmentTypes}
            name="investment"
            id="investment"
            value={formData.investment}
            onChange={(selectedOption) => handleChange(selectedOption, 'investment')}
          />
          {errors.investment && <p className="error">{errors.investment}</p>}
        </div>

        <br /><button type="submit" disabled={state.submitting} className="submit-button-blue">Submit</button>
      </div>

      <ValidationError field="name" prefix="Name" errors={state.errors} />
      <ValidationError field="email" prefix="Email" errors={state.errors} />
      <ValidationError field="country" prefix="Country" errors={state.errors} />
      <ValidationError field="propertyType" prefix="Property Type" errors={state.errors} />
      <ValidationError field="priceRange" prefix="Price Range" errors={state.errors} />
      <ValidationError field="buyOrRent" prefix="Buy or Rent" errors={state.errors} />
      <ValidationError field="residencyCitizenship" prefix="Residency or Citizenship" errors={state.errors} />
      <ValidationError field="project" prefix="Project Type" errors={state.errors} />
      <ValidationError field="investment" prefix="Investment Type" errors={state.errors} />
    </form>
  );
};

export default Form;
